// Admin.js (Frontend)

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import './Admin.css';

function ActivityTrends() {
  const [timeFrame, setTimeFrame] = useState('monthly');
  const [data, setData] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('all');

  const calculateTicks = (data, key) => {
    const maxCount = Math.max(...data.map(d => d[key]));
    const step = Math.ceil(maxCount / 5);
    return Array.from({ length: 5 }, (_, i) => (i + 1) * step);
  };

  useEffect(() => {
    fetchActiveUsers();
    fetchActivityData(timeFrame, selectedUser);
  }, [timeFrame, selectedUser]);

  const fetchActiveUsers = async () => {
    try {
      const response = await axios.get('/api/active_users');
      setActiveUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch active users:', error);
    }
  };

  const fetchActivityData = async (frame, user) => {
    try {
      const response = await axios.get(`/api/user_activities?timeFrame=${frame}&userId=${user}`);
      setData(response.data);
    } catch (error) {
      console.error('Failed to fetch activity data:', error);
    }
  };

  const handleTimeFrameChange = (frame) => {
    setTimeFrame(frame);
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const timeFrameOptions = [
    { label: 'D', value: 'daily' },
    { label: 'W', value: 'weekly' },
    { label: 'M', value: 'monthly' },
    { label: 'Y', value: 'yearly' }
  ];

  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthLabels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

  const formatXAxis = (tickItem) => {
    if (timeFrame === 'weekly') {
      return dayLabels[tickItem - 1];
    }
    if (timeFrame === 'yearly') {
      return monthLabels[tickItem - 1];
    }
    return tickItem;
  };

  return (
    <div className="admin-container">
      <div className="controls">
        {timeFrameOptions.map(frame => (
          <button
            key={frame.value}
            onClick={() => handleTimeFrameChange(frame.value)}
            className={`time-frame-button ${timeFrame === frame.value ? 'active' : ''}`}
          >
            {frame.label}
          </button>
        ))}
        <select onChange={handleUserChange} value={selectedUser} className="user-select">
          <option value="all">All Users</option>
          {activeUsers.map(user => (
            <option key={user.user_id} value={user.user_id}>{user.user_name}</option>
          ))}
        </select>
      </div>
      <h6>App Engagement</h6>
      <ResponsiveContainer width="100%" height={200}>
        {data && data.length > 0 ? (
          <BarChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tickFormatter={formatXAxis} />
            <YAxis ticks={calculateTicks(data, 'count')} />
            <Tooltip />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        ) : (
          <p>No data available or data is loading...</p>
        )}
      </ResponsiveContainer>
      <h6>Distinct Users</h6>
      <ResponsiveContainer width="100%" height={200}>
        {data && data.length > 0 ? (
          <BarChart data={data} margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" tickFormatter={formatXAxis} />
            <YAxis ticks={calculateTicks(data, 'distinct_users')} />
            <Tooltip />
            <Bar dataKey="distinct_users" fill="#82ca9d" />
          </BarChart>
        ) : (
          <p>No data available or data is loading...</p>
        )}
      </ResponsiveContainer>
    </div>
  );
}

function Admin() {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleApproveEmail = async () => {
    setStatusMessage('');
    setErrorMessage('');

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post('/api/preapprove-email', { email }, {
        headers: {
          'Content-Type': 'application/json',
          'x-user-id': 'YOUR_ADMIN_USER_ID' // Replace with actual Admin user ID or remove if handled by authentication
        }
      });

      if (response.data.message) {
        setStatusMessage(response.data.message);
        setEmail('');
      }
    } catch (error) {
      console.error('Error approving email:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to approve email. Please try again.');
      }
    }
  };

  return (
    <div className="admin-dashboard">
      <h3>Engagement Insights</h3>
      <ActivityTrends />
      
      {/* Preapprove Email Section */}
      <div className="preapprove-section">
        <h4>Preapprove a New User</h4>
        <div className="preapprove-form">
          <input
            type="email"
            placeholder="Enter user email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="preapprove-input"
          />
          <button onClick={handleApproveEmail} className="preapprove-button">
            Approve
          </button>
        </div>
        {statusMessage && <p className="success-message">{statusMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default Admin;
